import * as React from "react"
import HorseButton from "../../../components/horse-button"

import Layout from "../../../components/layout"
import LoadyHorse from "../../../components/loady-horse"
import Seo from "../../../components/seo"

function HorseTimes() {
  return (
    <Layout bodyClass="archiveOrg">
      <Seo title="Frog the Horse" />
      <div className="pageWrapper limit">
        <h1>Frog the Horse</h1>
        <p>
          A public domain book about a horse with an unlikely name! You can
          download the epub using the embed's controls if you really want to
          find out more about Frog and his many adventures. I'll never know; I
          didn't put tracking on this site.
        </p>
        <div className="iframeWrapper">
          <LoadyHorse />
          <iframe
            src="https://archive.org/embed/froghorsethatkne00meek"
            width="560"
            height="384"
            frameborder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowfullscreen
          ></iframe>
        </div>
        <HorseButton />
      </div>
    </Layout>
  )
}

export default HorseTimes
